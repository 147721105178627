<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            width="24"
            height="24"
            rx="6"
            fill="url(#paint0_linear_294_2745)"
        />
        <path
            d="M15 9.00002L9 15M8.99997 9L14.9999 15"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
        />
        <defs>
            <linearGradient
                id="paint0_linear_294_2745"
                x1="12"
                y1="0"
                x2="12"
                y2="24"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#E88B76" />
                <stop offset="1" stop-color="#CA5048" />
            </linearGradient>
        </defs>
    </svg>
</template>
