<script lang="ts" setup>
import { ref } from "vue";

import AlertError from "@/Components/Icons/AlertError.vue";
import AlertSuccess from "@/Components/Icons/AlertSuccess.vue";
import AlertInfo from "@/Components/Icons/AlertInfo.vue";
import AlertWarning from "@/Components/Icons/AlertWarning.vue";
import Close from "@/Components/Icons/Close.vue";

defineProps({
    variant: {
        type: String,
        default: null,
    },

    align: {
        type: String,
        default: "start",
    },

    title: {
        type: String,
        default: null,
    },

    closable: {
        type: Boolean,
        default: false,
    },
});

const show = ref(true);

const dismiss = () => {
    // Dismiss the alert with transition
    show.value = false;
};
</script>
<template>
    <div
        class="p-5 flex justify-between border rounded-md gap-4"
        :class="[
            !variant ? 'bg-info/20 border-info text-default' : '',
            {
                'items-center': align === 'center',
                'items-end': align === 'end',
                'items-start': align === 'start',
                'bg-error/20 border-error text-default': variant === 'error',
                'bg-success/20 border-success text-default':
                    variant === 'success',
                'bg-warning/20 border-warning text-default':
                    variant === 'warning',
                'bg-info/20 border-info text-default': variant === 'info',
            },
        ]"
        v-if="show"
    >
        <div>
            <template v-if="variant === 'error'">
                <AlertError class="w-6 h-6" />
            </template>
            <template v-else-if="variant === 'success'">
                <AlertSuccess class="w-6 h-6" />
            </template>
            <template v-else-if="variant === 'warning'">
                <AlertWarning class="w-6 h-6" />
            </template>
            <template v-else-if="variant === 'info'">
                <AlertInfo class="w-6 h-6" />
            </template>
        </div>
        <div class="flex-1 flex flex-col gap-2 self-stretch justify-center">
            <h2 class="text-sm font-semibold" v-if="title">
                {{ title }}
            </h2>
            <div class="text-sm">
                <slot />
            </div>
        </div>
        <div v-if="closable">
            <button type="button" @click="dismiss">
                <Close class="w-6 h-6" />
            </button>
        </div>
    </div>
</template>
