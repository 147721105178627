<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            width="24"
            height="24"
            rx="6"
            fill="url(#paint0_linear_294_2762)"
        />
        <path
            d="M12 9V14"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
        />
        <circle cx="12" cy="17" r="1" fill="white" />
        <defs>
            <linearGradient
                id="paint0_linear_294_2762"
                x1="12"
                y1="0"
                x2="12"
                y2="24"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#DBA948" />
                <stop offset="0.0001" stop-color="#FFC46B" />
                <stop offset="1" stop-color="#FFA318" />
            </linearGradient>
        </defs>
    </svg>
</template>
